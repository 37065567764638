<template>
  <div class="row m-0 text-center text-md-start">
    <div class="col-12 mb-4" v-if="mergedProject.fixed">
      <div class="row">
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-5 col-xl-4 border_end">
              <div class="row justify-content-center justify-content-md-start">
                <div class="col-12 bold font17 normal-text-dark pe-0">
                  1. Enter Quantity
                </div>
                <div class="col-10 col-md-12 col-lg-10 col-xl-9 col-xxl-8 mt-3">
                  <NumberField color="gold" v-model="donation.quantity" id="quantity" />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg mt-4 mt-md-0">
              <div class="row">
                <div class="col-12">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="row m-0">
                        <div class="col-12 bold green-text-dark font15 px-1">
                          Donation Total
                        </div>
                        <div class="col-12 total px-1">
                          {{donation.currency}}{{displayTotal}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="row">
        <div class="col-12 bold font17 normal-text-dark">
          <span v-if="mergedProject.fixed">2. Split Donation Types:</span><span v-else>1. Enter Donation Amounts</span>
        </div>
        <div class="col-12 col-md-11 font15 my-1">
          <span v-if="mergedProject.fixed">Choose how you would like to split your total donation amount across the eligible donation types:</span>
          <span v-else>Choose how you would like to split your intended donation amount across the eligible donation types:</span>
        </div>
        <div class="col-12 p-0">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="row mx-0 mt-3">
                <div class="col-6 col-lg-4 col-xl-3 mb-3" v-for="(type, key) in mergedProject.projectDonationTypeMaps" :key="key">
                  <div class="row m-0">
                    <div class="col-12 p-0">
                      <CurrencyField type="text" placeholder="0.00" id="amount" color="goldgold" v-model="donation.donationTypes[key].amount" :currencySymbol="defaultCurrency" @input="updateAmount(key)" />
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-12 bold green-text-dark font15 px-1">
                      {{type.donationType.description}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4 mb-3" v-if="!mergedProject.fixed">
              <div class="row m-0">
                <div class="col-12">
                  <div class="row m-0">
                    <div class="col-12 bold green-text-dark font15 px-1">
                      Donation Total
                    </div>
                    <div class="col-12 total px-1">
                      {{donation.currency}}{{displayTotal}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 font10">
              <div class="row mx-0">
                <div class="col-12" :class="{'error-text': errorMessage !== 'Ready to Donate'}">
                  {{errorMessage}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4" v-if="mergedProject.plaque">
      <div class="row">
        <div class="col-12 bold font17 normal-text-dark mb-2">
          <span v-if="mergedProject.fixed">3</span><span v-else>2</span>. Plaque Message
        </div>
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col-md-10">
              <TextArea placeholder='Enter your personalized plaque message here e.g. "Sponsored by [Donor Name] for the Esaal-e-Sawaab of [Name}"' v-model="donation.plaqueDescription" id="plaqueDescription" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-12 p-0">
      <div class="row m-0">
        <div class="col-6 mb-3" v-for="(type, key) in project.projectDonationTypeMaps" :key="key">
          <div class="row m-0">
            <div class="col-12 p-0">
              <CurrencyField type="text" placeholder="0.00" id="amount" color="goldgold" v-model="donation.donationTypes[key].amount" :currencySymbol="defaultCurrency" @change="updateAmount" />
            </div>
          </div>
          <div class="row m-0">
            <div class="col-12 p-0 text-center font12 bold">
              {{type.donationType.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-0 mb-3">
      <div class="row m-0 justify-content-center">
        <div class="col-auto p-0">
          Total:
        </div>
        <div class="col-auto bold">
          {{donation.currency}} {{displayTotal}}
        </div>
      </div>
    </div> -->
    <div class="col-12 p-0 mt-3">
      <div class="row m-0" v-if="cart">
        <div class="col-12 col-md-auto mb-2">
          <Button color="green" width="100" btnText="Update Cart" icon="arrow" @buttonClicked="updateCart" size="big" class="mb-1">
            <IconCart color="gold" size="size16" />
          </Button>
        </div>
        <!-- <div class="col-12 col-md-auto mb-2">
          <Button color="red" width="100" btnText="Cancel" icon="arrow" @buttonClicked="goBack" size="big" class="mb-1">
            <IconArrowForward color="white" size="size20" />
          </Button>
        </div> -->
      </div>
      <div class="row m-0" v-else>
        <div class="col-12 col-md-auto mb-2">
          <Button color="green" width="100" btnText="Added" icon="arrow" class="mb-1" disabled="disabled" v-if="isAdded" size="big" />
          <Button color="green" width="100" btnText="Add to Cart" icon="arrow" @buttonClicked="addDonationToCart" size="big" class="mb-1" v-else :disabled="disabled">
            <IconCart color="gold" size="size16" />
          </Button>
        </div>
        <div class="col-12 col-md-auto mb-2">
          <Button color="red" width="100" btnText="Donate Now" icon="arrow" @buttonClicked="goDonate" size="big" class="mb-1" :disabled="disabled">
            <IconArrowForward color="white" size="size20" />
          </Button>
        </div>
      </div>
    </div>
    <transition name="comeIn">
      <AddCartPopup v-if="isAdded" @close="isAdded = false"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('../components/CurrencyField.vue')),
    NumberField: defineAsyncComponent(() => import('../components/NumberField.vue')),
    TextArea: defineAsyncComponent(() => import('../components/TextArea.vue')),
    AddCartPopup: defineAsyncComponent(() => import('./AddCartPopup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconCart: defineAsyncComponent(() => import('../components/icons/IconCart.vue'))
  },
  props: ['project', 'cart', 'adminProject'],
  name: 'Project Donate',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      donation: {
        quantity: 1,
        amount: 0.00,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: [],
        plaqueDescription: ''
      },
      isAdded: false,
      disabled: true,
      errorMessage: 'Ready to Donate'
    }
  },
  beforeMount () {
    this.setDonationTypes()
    this.donation.currency = this.defaultCurrency
  },
  mounted () {
    if (this.mergedProject.fixed) {
      this.initialFixed()
    }
    this.checkAmount()
    this.setCartValues()
  },
  watch: {
    theCart: {
      deep: true,
      handler (val) {
        this.setCartValues()
      }
    },
    donation: {
      deep: true,
      handler (val) {
        this.checkAmount()
      }
    },
    donationQuantity () {
      this.setAmount()
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    cartKey () {
      if (this.cart && this.adminProject) {
        const isCart = (cartItem) => cartItem.projectId === this.adminProject.projectID
        return this.cart.findIndex(isCart)
      } else if (this.cart) {
        const isCart = (cartItem) => cartItem.projectId === this.project.adminProjectId
        return this.cart.findIndex(isCart)
      }
      return null
    },
    theCart () {
      if (this.cart && this.adminProject) {
        return this.cart.filter(cartItem => {
          if (cartItem.projectId !== this.adminProject.projectID) {
            return false
          }
          return true
        })
      } else if (this.cart) {
        return this.cart.filter(cartItem => {
          if (cartItem.projectId !== this.project.adminProjectId) {
            return false
          }
          return true
        })
      }
      return null
    },
    donationQuantity () {
      return this.donation.quantity
    },
    mergedProject () {
      if (this.adminProject) {
        return this.adminProject
      } else if (this.project && this.project.adminProjectId) {
        const adminProject = this.adminProjects.find(p1 => p1.projectID === this.project.adminProjectId)
        const mergedProject = { ...this.project, ...adminProject }
        return mergedProject
      } else {
        return this.project
      }
    },
    emptyDonationTypes () {
      return this.donation.donationTypes.filter(type => {
        if (type.amount !== 0 && type.amount !== '0' && type.amount !== '' && type.amount !== null) {
          return false
        }
        return true
      })
    },
    nonEmptyDonationTypes () {
      return this.donation.donationTypes.filter(type => {
        if (type.amount === 0 || type.amount === '0' || type.amount === '' || type.amount === null) {
          return false
        }
        return true
      })
    },
    displayTotal () {
      return parseFloat(Math.round(this.donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  },
  methods: {
    ...mapActions([
      'addToCart',
      'clearCart',
      'editCart'
    ]),
    setCartValues () {
      if (this.theCart && this.theCart.length > 0) {
        this.donation = this.theCart[0]
      }
    },
    checkAmount () {
      if (this.mergedProject.fixed) {
        var total = 0
        this.nonEmptyDonationTypes.forEach(type => {
          total = (parseFloat(total) + parseFloat(type.amount)).toFixed(2)
        })
        if (total < this.donation.amount) {
          this.disabled = true
          this.errorMessage = 'Your donation needs another ' + this.defaultCurrency + ' ' + (parseFloat(this.donation.amount) - parseFloat(total)).toFixed(2)
        } else if (total > this.donation.amount) {
          this.disabled = true
          this.errorMessage = 'Your donation is too big, remove ' + this.defaultCurrency + ' ' + (parseFloat(total) - (parseFloat(this.donation.amount))).toFixed(2)
        } else {
          this.disabled = false
          this.errorMessage = 'Ready to Donate'
        }
      } else {
        if (this.donation.amount < 0.01) {
          this.disabled = true
          this.errorMessage = 'Donation needs to be greater than 0'
        } else {
          this.disabled = false
          this.errorMessage = 'Ready to Donate'
        }
      }
    },
    setDonationTypes () {
      if (this.adminProject) {
        this.donation.projectId = this.adminProject.projectID
        this.adminProject.projectDonationTypeMaps.forEach(donationtype => {
          const typeObject = {}
          typeObject.type = donationtype
          this.donation.donationTypes.push(typeObject)
        })
        this.donation.donationTypes.forEach(type => {
          type.amount = null
        })
      } else if (this.project && this.project.adminProjectId) {
        const adminProject = this.adminProjects.find(p1 => p1.projectID === this.project.adminProjectId)
        const mergedProject = { ...this.project, ...adminProject }
        this.donation.projectId = mergedProject.projectID
        mergedProject.projectDonationTypeMaps.forEach(donationtype => {
          const typeObject = {}
          typeObject.type = donationtype
          this.donation.donationTypes.push(typeObject)
        })
        this.donation.donationTypes.forEach(type => {
          type.amount = null
        })
      }
      // this.donation.projectId = this.project.projectID
      // this.project.projectDonationTypeMaps.forEach(donationtype => {
      //   const typeObject = {}
      //   typeObject.type = donationtype
      //   this.donation.donationTypes.push(typeObject)
      // })
      // this.donation.donationTypes.forEach(type => {
      //   type.amount = null
      // })
    },
    initialFixed () {
      this.setAmount()
      this.donation.donationTypes[0].amount = this.donation.amount.toString()
    },
    setAmount () {
      if (this.mergedProject.fixed) {
        this.donation.amount = this.donation.quantity * this.mergedProject.cost
        this.multiplyAmount()
      }
    },
    multiplyAmount () {
      if (this.mergedProject.fixed) {
        var total = 0
        this.nonEmptyDonationTypes.forEach(type => {
          total = (parseFloat(total) + parseFloat(type.amount)).toFixed(2)
        })
        const ratioTop = (parseFloat(this.donation.amount)).toFixed(0)
        const ratioBottom = ((parseFloat(total))).toFixed(0)
        if (this.donationQuantity && !this.disabled && ratioTop && ratioBottom && ((ratioTop !== 'NaN' && ratioTop !== 'Infinity' && ratioTop !== '0' && ratioTop !== 0) || (ratioBottom !== 'NaN' && ratioBottom !== 'Infinity' && ratioBottom !== '0' && ratioBottom !== 0))) {
          this.nonEmptyDonationTypes.forEach(type => {
            type.amount = (parseFloat(type.amount) * ratioTop / ratioBottom).toFixed(2)
          })
        }
      }
    },
    updateAmount (key) {
      if (this.mergedProject.fixed) {
        var total = 0
        this.nonEmptyDonationTypes.forEach(type => {
          total = (parseFloat(total) + parseFloat(type.amount)).toFixed(2)
        })
        if (this.emptyDonationTypes.length > 0 && parseFloat(total).toFixed(2) < this.donation.amount) {
          this.emptyDonationTypes[0].amount = (parseFloat(this.donation.amount) - parseFloat(total)).toFixed(2)
        }
      } else {
        let ret = 0
        this.donation.donationTypes.forEach(type => {
          ret += Number(type.amount)
        })
        this.donation.amount = ret
      }
    },
    goDonate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.addToCart(this.donation)
      this.isAdded = true
      this.donation = {
        quantity: 1,
        amount: 0.00,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: [],
        plaqueDescription: ''
      }
      this.setDonationTypes()
    },
    async updateCart () {
      const item = {
        key: this.cartKey,
        item: this.donation
      }
      await this.editCart(item)
      this.$router.push('/checkout/0')
    },
    goBack () {
      this.$router.push('/checkout/0')
    }
  }
}
</script>

<style scoped>
.card_holder {
  border-radius: 15px;
}
.title {
  color: #3A3A3A;
  font-family: "quicksand_bold", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}
.bg {
  background-color: #F4F4F4;
}
.bg:hover {
  background-color: #ffffff;
}
.bg_green {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.above_z {
  position: relative;
  z-index: 2;
}
.total {
  font-family: "quicksand", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  color: var(--green-color);
  line-height: 1;
}
.error-text {
  font-size: 10px;
  color: red;
}
.border_end {
  border-right: none;
}
@media (min-width: 992px) {
  .border_end {
    border-right: 1px solid rgb(204, 204, 204);
  }
}
</style>
